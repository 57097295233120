.card-diff-section .containers {
  width: 100%;
  padding: 50px 0;
  position: relative;
  overflow: hidden;
}

.card-diff-section .containers:before {
  content: '';
  position: absolute;
  top: 0px;
  margin-left: 26px;
  width: 2px;
  height: 100%;
  background: #CCD1D9;
  z-index: 1
}

.timeline-block {
  width: -webkit-calc(50% + 8px);
  width: -moz-calc(50% + 8px);
  width: calc(50% + 8px);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  justify-content: space-between;
  clear: both;
}

.timeline-block-right {
  float: right;
}

.timeline-block-left {
  float: left;
  direction: rtl
}

.marker {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #F5F7FA;
  background: #f5fbff;
  margin-top: 10px;
  z-index: 9999;
 text-align:center;
     position: relative;
 left:4px;
}

.marker.active{
 width: 40px;
 height: 37px;
 /* background:#5bd040; */
border: 2px solid #dfe8ed;
 left: 0px;
}

.hover-underline:hover{
text-decoration: underline;
}
.timeline-content {
  width: 95%;
  padding: 0 15px;
  color: #666
}
svg.fa-clipboard-list{
 display:none;
}
svg.fa-clipboard-list.active {
display:block !important;
   position: absolute;
   top: 0;
   right: 0;
   left: 0;
   bottom: 0;
   margin: auto;
   height: 50% !important;
 color:#aed1fb!important;
}

.timeline-content h3 {
  margin-top: 8px;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 500
}

.timeline-content span {
  font-size: 15px;
  color: #a4a4a4;
}

.timeline-content p {
  font-size: 14px;
  line-height: 1.5em;
  word-spacing: 1px;
  color: #888;
}



  .containers:before {
     left: 178px;
     width: 2px;
  }

  @media only screen and (max-width: 1000px){
    .containers:before {
      width: 2px;
   }
    }
    @media only screen and (max-width: 730px){
      .containers:before {
        width: 2px;
     }
      }

      @media only screen and (max-width: 580px){
        .containers:before {
          width: 2px;
       }
        }
       
          
              
  .timeline-block {
     width: 100%;
  }
  .timeline-block-right {
     float: none;
  }

  .timeline-block-left {
     float: none;
     direction: ltr;
  }

  .comment-feedback-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 8px;
}

.comment-container,
.feedback-container {
    width: 100%;
    padding: 8px;
    margin-bottom: 12px;
}

.comment-container h3,
.feedback-container h3 {
    margin-top: 0;
}

/* .comment-container form {
    margin: 12px 0;
} */

.comment-container input[type="text"],
.feedback-container input[type="text"] {
    flex-grow: 1;
    margin-right: 8px;
    padding: 10px;
    font-size: 14px;
}
.ant-steps-item-icon{
  line-height: 1.5;
}


.feedback-container select {
    padding: 4px;
    font-size: 14px;
    border: 1px solid #a5b4fc;
    border-radius: 5px;
    margin-right: 12px;
}

.feedback-container select:focus {
    outline: none;
    border: 1px solid #6366f1;
}

.comment-list,
.feedback {
    padding: 4px;
    margin-bottom: 8px;
    border-bottom: 1px dashed #ccc;
}

.comment-body p,
.feedback-body p {
    margin: 0;
}

.comment-footer,
.feedback-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
    font-size: 14px;
}

.comment-footer p,
.feedback-footer p {
    margin: 0;
}


.feedback .feedback-footer {
    position: relative;
}

.feedback-status-dropdown {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 5px;
    z-index: 10;
}

.feedback-status-dropdown button {
    background-color: #fff;
    border: none;
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
}

.feedback-status-dropdown button:hover {
    background-color: #f7f7f7;
}


@media screen and (max-width: 1700px) {
  .col-css-custom {
    width:30% !important;
  }
}

@media screen and (max-width: 1500px) {
  .col-css-custom {
    width:33% !important;
  }
}