.st-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.st-tabs {
    display: flex;
    width: 100%;
    position: relative;
    gap: 8px;
}

.sl-underline {
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 1px;
    background: #8855ff;
}