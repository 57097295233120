.my-modal-class .ant-modal-body{
    height: 80vh;
    overflow: auto;
    padding: 0 !important;
   
}

.info_circle {
    font-size: 18px;
    color: #6777ef;
    cursor: pointer;
}