.tableCustom tr td {
    border: 2px solid white !important;
    font-size: 14px;
}

.tableCustom tr th {
    border: 2px solid white !important;
    font-size: 14px;
}

.theme-white .tableCustom tr th {
    height: auto;
    /* font-weight: 500; */
    color: #666;
    border: 2px solid white !important;
}

.theme-white .tableCustom tr td {
    border: 2px solid white !important;
}

.dark.theme-black .tableCustom.table.table-role tr th {
    border: 2px solid #f0f0f0 !important;
}

.dark.theme-black .tableCustom.table.table-role tr td {
    border: 2px solid #f0f0f0 !important;
}

.tableCustom.table-role tr td {
    border: 2px solid #f0f0f0 !important;
    font-size: 14px;
}

.tableCustom.table-role tr th {
    border: 2px solid #f0f0f0 !important;
    font-size: 14px;
}

.theme-white .tableCustom.table.table-role tr th {
    border: 2px solid #f0f0f0 !important;
}

.theme-white .tableCustom.table-role tr td {
    border: 2px solid #f0f0f0 !important;
}

.dark.theme-black .tableCustom.table-role tr th {
    border: 2px solid #f0f0f0 !important;
}

.dark.theme-black .tableCustom.table-role tr td {
    border: 2px solid #f0f0f0 !important;
}

.theme-black .tableCustom tr.trheigth:hover {
    background-color: rgb(216, 216, 216) !important;
}

.theme-white .tableCustom tr.trheigth:hover {
    background-color: rgb(216, 216, 216) !important;
}

.l-bg-blue_light {
    background: #93c5fd !important;
    color: #000;
    font-size: 13px !important;
}

.l-bg-yello_light {
    background: #fde047 !important;
    color: #000;
    font-size: 13px !important;

}

.l-bg-red_light {
    background:  #fca5a5 !important;
    color: #000;
    font-size: 13px !important;


}