/* #tooltip {
    background: rgb(255, 251, 251);
    color: rgb(0, 0, 0);
    padding: 4px 8px;
    font-size: 13px;
    border-radius: 4px;
}

#tooltip.hidden {
    visibility: hidden;
    pointer-events: none;
} */

#arrow,
#arrow::before {
    position: absolute;
    width: 12px;
    height: 12px;
    /* z-index: -1; */
}

#arrow::before {
    content: " ";
    background-color: white;
    box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0px;
    left: 0;
    transform: rotate(45deg);
    width: 12px;
    height: 12px;
}

#tooltip[data-popper-placement^="top"]>#arrow {
    bottom: -4px;
}

#tooltip[data-popper-placement^="bottom"]>#arrow {
    top: -4px;
}

#tooltip[data-popper-placement^="left"]>#arrow {
    right: -4px;
}

#tooltip[data-popper-placement^="right"]>#arrow {
    left: -4px;
}

#tooltip[data-popper-reference-hidden="true"],
#tooltip[data-popper-escaped="true"] {
    visibility: hidden;
    pointer-events: none;
}

.Toastify__toast-container {
    top: -0.5rem;
    width: max-content;
    max-width: 420px;
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
    background: #F46558;
    cursor: default;
    padding-bottom: 0px;
    padding-top: 0px;
}

.Toastify__toast-theme--colored.Toastify__toast--info {
    cursor: default;
    padding-bottom: 0px;
    padding-top: 0px;
}

.Toastify__close-button {
    padding: 5px;
}

.Toastify__toast-icon {
    width: 15px;
}

.ant-popover-placement-bottomRight .ant-popover-arrow {
    right: 75px !important;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
    cursor: default;
    padding-bottom: 0px;
    padding-top: 0px;
}