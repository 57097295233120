.transceiver-ui .table td,.transceiver-ui .table:not(.table-bordered) th {
    border: 2px solid #dee2e6 !important;
    height: 40px !important;
}

.transceiver-ui p{
    margin-bottom: 0px !important;
}

.transceiver-ui h1{
    margin-bottom: 4px;
}
.transceiver-ui .table{
   margin-bottom: 4px !important; 
}