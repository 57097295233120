.ButtonLoader {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 2px 4px;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    color: black;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
}

.ButtonLoader.isLoading {
    pointer-events: none;
}

.Spinner {
    position: relative;
    width: 28px;
    height: 28px;
}

.Spinner:before,
.Spinner:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0.5;
}

.Spinner:before {
    /* background-color: #007bff; */
    z-index: 1000;
}

.Spinner:after {
    background-color: transparent;
    border: 4px solid #4338ca;
    border-top-color: transparent;
    z-index: 2000;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.DottedSpinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
}

.Dot {
    width: 8px;
    height: 8px;
    margin-right: 8px;
    border-radius: 50%;
    background-color: #007bff;
    animation: dot 1s infinite;
}

.Dot:nth-child(1) {
    animation-delay: 0s;
}

.Dot:nth-child(2) {
    animation-delay: 0.2s;
}

.Dot:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes dot {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-8px);
    }

    100% {
        transform: translateY(0);
    }
}